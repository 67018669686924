import { Layout } from "~/Layout/Layout";
import { Link, createRouteData, useRouteData } from "solid-start";
import { useParams } from "solid-start";
import { fetchPool } from "~/utils/fetchPool";
import IdInfo from "~/components/IdInfo/IdInfo";
import { For } from "solid-js";
import fetchBaseUrl from "~/utils/fetchBaseUrl";
import generatePayload from "~/utils/generatePayload";
import axios from 'axios';

function toBase64Url(hexString: string) {
    const bytes = hexToBytes(hexString);
    let binary = '';
    for (let i = 0; i < bytes.length; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

function hexToBytes(hex: string) {
    const bytes = [];
    for (let c = 0; c < hex.length; c += 2) {
        bytes.push(parseInt(hex.substr(c, 2), 16));
    }
    return bytes;
}

async function fetchTransactions(address: string) {
    const queryTemplate = `
    query($cursor: String) {
      transactions(
        owners: ["${address}"],
        after: $cursor
      ) {
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            id
          }
        }
      }
    }`;

    let transactions = [];
    let cursor = null;
    let hasNextPage = true;

    while (hasNextPage) {
        const variables = {
            cursor: cursor
        };
        const response = await fetch('https://arweave.net/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                query: queryTemplate,
                variables: variables
            })
        });
        const data = await response.json();
        if (data.errors) {
            console.error("Errors returned from GraphQL API:", data.errors);
            break;
        }
        const edges = data.data.transactions.edges;
        transactions.push(...edges.map((edge: any) => edge.node.id));
        hasNextPage = data.data.transactions.pageInfo.hasNextPage;
        if (hasNextPage) {
            cursor = edges[edges.length - 1].cursor;
        }
    }

    return transactions;
}

export function routeData() {
    const rawParam = useParams();

    const param = (rawParam.id as string).endsWith('@') ? decodeURIComponent(rawParam.id.slice(0, -1)) : decodeURIComponent(rawParam.id)


    const id_data = createRouteData(async () => {
        const pool = await fetchPool();
        const client = await pool?.connect();

        try {
            const id = await client.query(`SELECT * FROM allids WHERE LOWER(fullname) LIKE LOWER('${param}%') or iaddr = '${param}'`);
            let updates;
            if (id.rowCount === 1) {
                const iaddress = id.rows[0].iaddr
                const id_updates = await client.query(`SELECT * FROM idupdates WHERE iaddr = '${iaddress}'`);
                updates = id_updates.rows
            }
            const times = await client.query("SELECT * FROM updatetimes");

            const { rows } = times;

            client.release()
            let profileData = undefined
            if (id.rowCount === 1) {
                try {
                    if (id.rows[0]?.rawidentity.identity.contentmap) {
                        const contentMap = id.rows[0]?.rawidentity.identity.contentmap;
                        if ('cf19fddae8aa266c8d0d4807196681666cfd4562' in contentMap || '6245fd6c6681661907480d8d6c26aae8dafd19cf' in contentMap) {
                            const arweave_hash = contentMap['cf19fddae8aa266c8d0d4807196681666cfd4562'] || contentMap['6245fd6c6681661907480d8d6c26aae8dafd19cf'];
                            const arweave_address = toBase64Url(arweave_hash);
                            const transactions = await fetchTransactions(arweave_address);
                            console.log(transactions)
                            let profileRendered = false
                            const cached = []

                            for (const id of transactions) {
                                try {
                                    const { data } = await axios.get(`https://arweave.net/${id}`);
                                    console.log(data)
                                    cached.push(data);
                                    if (data['iEXZ3nd4K9fmGDSiQ8J6XLATzUUSKp1eAz']) {
                                        profileData = data
                                        profileRendered = true;
                                        break;
                                    }
                                } catch (error) {
                                    // Ignore JSON parsing errors and continue
                                }
                            }

                            if (!profileRendered && typeof cached[0] === 'string') {
                                profileData = cached[0]
                            }

                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            return {
                id: id.rows,
                updates,
                profileData,
                blockheight: rows[0].blockheight
            }
        } catch (e) {
            client.release()
            return null
        }

    });
    return { id_data };
}

export default function IdLookup() {
    const { id_data } = useRouteData<typeof routeData>();
    //if one result just display p`age, if more than one display cards in which users can select to navigate to id page}
    const handleClick = (iaddr: string) => {
        window.location = `${fetchBaseUrl()}/idlookup/${iaddr}` as any;
    }

    return (
        <Layout>
            {id_data()?.id.length === 0 && (
                <div class="px-28 text-white text-[4rem] mt-5">
                    No ID Found
                </div>
            )}
            {id_data()?.id.length === 1 && (
                <IdInfo id_info={id_data()?.id} updates={id_data()?.updates} blockheight={id_data()?.blockheight} />
            )}
            {(id_data() as any)?.id.length > 1 && (
                <div class="p-10 lg:px-32 text-white flex flex-col space-y-5 max-w-[75%]">
                    <div class="text-3xl lg:text-[3rem]">Select An ID:</div>
                    <div class="flex flex-wrap gap-5">
                        <For each={id_data()?.id}>
                            {(id) => {
                                return (
                                    <div onClick={() => handleClick(id.iaddr)} class='p-4 outline outline-2 outline-white rounded hover:cursor-pointer hover:bg-white hover:text-black' >
                                        {id.fullname}
                                    </div>
                                )
                            }}
                        </For>
                    </div>

                </div>
            )}
        </Layout>
    );
}
